import React from "react";

const Section3 = () => {
  const technicalSkills = [
    { name: "Flutter", level: "Expert" },
    { name: "React", level: "Expert" },
    { name: "Kotlin", level: "Expert" },
    { name: "Swift", level: "Expert" },
    { name: "Python", level: "Intermediate" },
    { name: "Java", level: "Intermediate" },
    { name: "C++", level: "Intermediate" },
  ];

  const softSkills = [
    "Teamwork",
    "Creativity",
    "Leadership",
    "Adaptability",
    "Communication",
    "Organizational",
    "Decision Making",
    "Problem Solving",
    "Critical Thinking",
  ];

  const tools = ["WordPress", "Office", "LaTeX", "Matlab", "ROS"];

  return (
    <div className="container mx-auto py-8 lg:text-start text-center lg:space-x-12 lg:px-12">
      <h2 className="text-4xl font-semibold mb-8 ">Skills</h2>

      {/* Technical Skills */}
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Technical Skills</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {technicalSkills.map((skill, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition duration-300"
            >
              <h3 className="text-xl font-semibold mb-4">{skill.name}</h3>
              <p className="text-gray-600">Level: {skill.level}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Soft Skills */}
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Soft Skills</h3>
        <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
          {softSkills.map((skill, index) => (
            <div
              key={index}
              className="bg-palette21 text-white font-semibold py-2 px-4 rounded-full"
            >
              {skill}
            </div>
          ))}
        </div>
      </div>

      {/* Tools */}
      <div>
        <h3 className="text-2xl font-semibold mb-4 ">Tools</h3>
        <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
          {tools.map((tool, index) => (
            <div
              key={index}
              className="bg-palette3 text-white font-semibold py-2 px-4 rounded-full"
            >
              {tool}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Section3;
