// Section1.tsx
import React from "react";
import profileImage from "../assets/images/photo.jpg";
import ScreenParticles from "../Components/ScreenParticles";
import cv from "../assets/pdf/cv.pdf";

const Section1: React.FC = () => {
  return (
    <div className="flex flex-col z-10 items-center justify-center  w-full h-full">
      <ScreenParticles />
      <img
        src={profileImage}
        alt="Profile"
        className="w-60 h-60 rounded-full mb-8"
      />
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Welcome to my Web Site!
        </h1>
        <p className="lg:px-32">
          My name is <span className="text-palette3">Giuseppe Gillini</span> and
          I am the <span className="text-palette3">C.T.O.</span> of{" "}
          <a
            href="https://www.everybotics.it"
            target="_blank"
            rel="noopener noreferrer"
            className="text-palette21 "
          >
            EveryBotics S.R.L.{" "}
          </a>
          In June 2021, I received a Ph.D. degree in Methods, Models, and
          Technologies for Engineering after a three-year program. I have a
          passion for exploring new technologies and finding innovative
          solutions to complex problems. Over the years, I have honed my skills
          in programming languages such as{" "}
          <span className="text-palette3">C++</span>,{" "}
          <span className="text-palette3">React</span>,{" "}
          <span className="text-palette3">Flutter</span>,{" "}
          <span className="text-palette3">Swift</span>, and{" "}
          <span className="text-palette3">Kotlin</span>, and have worked on a
          variety of projects ranging from web-based applications to control
          systems for autonomous robots.
        </p>
      </div>
      <a href={cv} target="new" download="Giuseppe_Gillini_CV.pdf">
        <button className="bg-palette21 hover:bg-palette3 text-white font-bold py-3 px-6 rounded-md shadow-md transition duration-300 ease-in-out">
          Download CV
        </button>
      </a>
    </div>
  );
};

export default Section1;
