import React from "react";

const Session4 = () => {
  const workExperiences = [
    {
      position: "Chief Technology Officer",
      description:
        "Web and Mobile Applications; IOT Applications; Creating technical reports on Industry 4.0 to apply for the tax credit in Italy.",
      company: "EveryBotics S.R.L.",
      period: "Since 2021",
    },
    {
      position: "Post-Doc Research Fellow",
      description: "Assistive and Collaborative Robotics",
      company: "UNICAS",
      period: "2021–2023",
    },
    {
      position: "Ph.D. Student",
      description:
        "Robotic Systems; Assistive Robotics; Fault Detection and Isolation for Multi-Robot Systems.",
      company: "UNICAS",
      period: "2017–2020",
    },
    {
      position: "Guest Researcher",
      description:
        "Integration of an EEG system with the hardware and software architecture of the robotic platform EDAN; Developing of an EEG-based interface relying on SSVEP and P300 to command EDAN;",
      company: "Deutsches Zentrum für Luft-und Raumfahrt",
      period: "October 2019 – March 2020",
    },
    {
      position: "Three Months Trainee",
      description:
        "Study of the new automatism plant of Presenzano Power Station; Asset Management; Safety at Work.",
      company: "ENEL",
      period: "2017",
    },
  ];

  return (
    <div className="container mx-auto py-8 text-center lg:text-start  lg:space-x-12 lg:px-12">
      <h2 className="text-4xl font-semibold mb-8">Work Experience</h2>

      <div className="relative">
        {workExperiences.map((experience, index) => (
          <div
            key={index}
            className={`mb-8 lg:mb-4 flex flex-col lg:flex-row items-center ${
              index % 2 === 0 ? "lg:flex-row-reverse" : ""
            }`}
          >
            <div className={`lg:w-1/2 ${index % 2 === 0 ? "lg:pr-8" : "lg:pl-8"}`}>
              <div
                className={`${
                  index % 2 === 0 ? "lg:text-left lg:pl-8 " : " lg:pr-8 lg:text-right"
                }`}
              >
                <span className="text-palette21">{experience.period}</span>
              </div>
            </div>
            <div className={`lg:w-1/2 ${index % 2 === 0 ? "lg:pr-8" : "lg:pl-8"}`}>
              <div className="bg-white rounded-lg shadow-md p-6 relative z-10">
                <h3 className="text-xl font-semibold mb-2 text-palette2">
                  {experience.position}
                </h3>
                <p className="text-sm text-palette3 mb-2">
                  {experience.company}
                </p>
                <p className="text-sm text-gray-700">
                  {experience.description}
                </p>
              </div>
            </div>
            {index !== workExperiences.length - 1 && (
              <div className="lg:absolute top-0 left-1/2 transform -translate-x-1/2 w-0.5 h-full bg-gray-300 hidden lg:block"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Session4;
