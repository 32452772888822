// Layout.tsx
import React, { ReactNode, useState } from "react";
import { FiHome, FiUser, FiLayers, FiBriefcase, FiMail } from "react-icons/fi";
import { Link, animateScroll as scroll } from "react-scroll";

interface LayoutProps {
  sections: { [key: string]: JSX.Element };
  currentSection: string;
  changeSection: (sectionNumber: string) => void;
}

const Layout: React.FC<LayoutProps> = ({
  sections,
  currentSection,
  changeSection,
}) => {
  const renderCurrentSection = () => {
    return sections[currentSection];
  };

  return (
    <div className="flex lg:flex-row flex-col lg:h-screen ">
      <div className="lg:w-1/6 lg:h-full w-full lg:text-center bg-palette2 text-white  flex flex-col justify-between z-50">
        <div className="flex lg:flex-col lg:flex-grow">
          <NavItem
            icon={<FiHome />}
            title="Home"
            target="home"
            active={currentSection === "home"}
            currentSection={currentSection}
            changeSection={changeSection}
          />
          <NavItem
            icon={<FiUser />}
            title="About"
            target="about"
            active={currentSection === "about"}
            currentSection={currentSection}
            changeSection={changeSection}
          />
          <NavItem
            icon={<FiLayers />}
            title="Skills"
            target="skills"
            active={currentSection === "skills"}
            currentSection={currentSection}
            changeSection={changeSection}
          />
          <NavItem
            icon={<FiBriefcase />}
            title="Experience"
            target="experience"
            active={currentSection === "experience"}
            currentSection={currentSection}
            changeSection={changeSection}
          />
          <NavItem
            icon={<FiMail />}
            title="Contacts"
            target="contact"
            currentSection={currentSection}
            changeSection={changeSection}
            active={currentSection === "contact"}
          />
        </div>
        <div className="hidden lg:block text-xs text-center mb-4 text-gray-400 mt-4">
          © 2024 www.giuseppegillini.it
        </div>
      </div>
      <div className="lg:w-5/6 w-full lg:h-full overflow-y-scroll bg-white p-4 z-10">
        {renderCurrentSection()}
      </div>
      <div className="lg:hidden mb-4 text-xs text-center text-gray-400 mt-4">
        © 2024 www.giuseppegillini.it
      </div>
    </div>
  );
};

interface NavItemProps {
  icon: JSX.Element;
  title: string;
  target: string;
  active: boolean;
  currentSection: string;
  changeSection: (sectionNumber: string) => void;
}
const NavItem: React.FC<NavItemProps> = ({
  icon,
  title,
  target,
  currentSection,
  active,
  changeSection,
}) => {
  const handleClick = () => {
    changeSection(target);
  };

  return (
    <div
      className={`flex items-center justify-center cursor-pointer w-full h-full ${
        active ? " text-white" : "hover:text-palette21 text-gray-300 "
      } relative`}
      onClick={handleClick}
    >
      <div
        className={`flex flex-col items-center justify-center p-4 w-full h-full ${
          active ? "bg-palette21" : "hover:bg-palette22 "
        }`}
      >
        <div className={`mb-2 lg:text-2xl`}>{icon}</div>
        <div className={` hidden lg:block lg:text-lg `}>{title}</div>
      </div>
    </div>
  );
};

export default Layout;
