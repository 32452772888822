// App.tsx
import React, { useState } from "react";
import Layout from "./Components/Layout";
import Section1 from "./Pages/Section1";
import Section2 from "./Pages/Section2";
import Section3 from "./Pages/Section3";
import Section4 from "./Pages/Section4";
import Section5 from "./Pages/Section5";

const App: React.FC = () => {
  const [currentSection, setCurrentSection] = useState("home");

  const changeSection = (sectionNumber: string) => {
    setCurrentSection(sectionNumber);
    console.log(sectionNumber);
  };

  return (
    <Layout
      currentSection={currentSection}
      changeSection={changeSection}
      sections={{
        home: <Section1 />,
        about: <Section2 />,
        skills: <Section3 />,
        experience: <Section4 />,
        contact: <Section5 />,
      }}
    />
  );
};

export default App;
