
const personalInterests = [
    "Robotics",
    "Software",
    "Artificial Intelligence",
    "Maths",
    "Music",
    "Gaming",
    "Traveling",
    "Cooking",
  ];
  
  

  export default personalInterests;