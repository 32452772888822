import React from "react";
import Roadmap from "../Components/Roadmap";
import milestones from "../shared/milestones";
import { motion } from "framer-motion";
import personalInterests from "../shared/personalIntersts";
import awards from "../shared/awards";
import postgraduateCourses from "../shared/postgraduated";
import skillsAndLanguages from "../shared/skills";

const Section2 = () => {
  return (
    <div className="flex flex-col py-8 mx-auto  lg:flex-row justify-center items-center lg:space-x-12 lg:px-12">
      <div className="lg:w-1/2">
        <h2 className="text-4xl font-semibold mb-8 text-center lg:text-start">
          My Journey
        </h2>

        {/* Timeline for Milestones */}
        <div className="space-y-8">
          {milestones.map((milestone, index) => (
            <div
              className="flex lg:flex-row flex-col items-center  lg:items-start space-x-4"
              key={index}
            >
              <div className="w-8 h-8 rounded-full bg-palette21 lg:mb-0 mb-2 flex items-center justify-center text-white flex-shrink-0">
                <span className="text-xs">{index + 1}</span>
              </div>
              <div className="bg-white border border-gray-300 shadow-md rounded-lg p-4 flex-grow">
                <h3 className="text-lg font-semibold mb-1">
                  {milestone.title}
                </h3>
                <p className="text-sm text-gray-600 mb-2">{milestone.date}</p>
                <p className="text-sm text-gray-700">{milestone.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="lg:w-1/2 lg:text-start text-center">
        {/* Awards Section */}
        <div className="mt-12 lg:mt-0">
          <h3 className="text-2xl font-semibold  mb-4">Awards</h3>
          <div className="space-y-4">
            {awards.map((award, index) => (
              <div key={index}>
                <h4 className="text-lg font-semibold">{award.title}</h4>
                <p className="text-sm text-gray-600">
                  {award.date} - {award.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Interests Section */}
        <div className="mt-12">
          <h3 className="text-2xl font-semibold mb-4">Personal Interests</h3>
          <div className="flex flex-wrap items-center lg:items-start justify-center lg:justify-start">
            {personalInterests.map((interest, index) => (
              <div
                key={index}
                className="bg-palette21 text-white font-semibold py-1 px-4 rounded-full mr-2 mb-2"
              >
                {interest}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
