import React from "react";
import {
  FiMail,
  FiPhone,
  FiMapPin,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
} from "react-icons/fi";
import ScreenParticles from "../Components/ScreenParticles";
import logoeb from "../assets/images/logo_eb.png";

const Section5 = () => {
  return (
    <div className="flex flex-col z-10 items-center justify-center  w-full h-full">
      <ScreenParticles />

      <h2 className="text-5xl font-semibold mb-8 text-center">Contact Me</h2>

      <div className="flex flex-col md:flex-row gap-20 py-8 justify-center">
        <div className="w-full md:w-1/2 ">
          <h3 className="text-xl font-semibold mb-4">Get in Touch</h3>
          <div className="flex items-center gap-2 mb-2">
            <span className="text-palette21 mr-2">
              <FiMail />
            </span>
            <p className="text-gray-600">gillinigiuseppe@gmail.com</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 ">
          <h3 className="text-xl font-semibold mb-4">Social</h3>
          <div className="flex gap-2 items-center mb-2 justify-center">
            <span className="text-palette21 ">
              <FiLinkedin />
            </span>
            <a
              href="https://www.linkedin.com/in/giuseppegillini/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600"
            >
              LinkedIn
            </a>
          </div>
          <div className="flex gap-2 items-center justify-center">
            <span className="text-palette21 ">
              <FiInstagram />
            </span>
            <a
              href="https://www.instagram.com/doppiag93/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600"
            >
              Instagram
            </a>
          </div>
        </div>
      </div>
      <img src={logoeb} alt="logoEB" className="w-40 h-40 rounded-full " />
      <div className="py-8 text-center lg:px-32">
        <h3 className="text-xl font-semibold mb-4">
          Discover innovation with EveryBotics:
        </h3>
        <p className="text-gray-600 mb-4">
          As one of the co-founders of EveryBotics, I'm proud to lead the
          leading Italian company in the fields of emerging technologies,
          robotics, and advanced software. We collaborate with companies and
          institutions to turn ideas into reality. At EveryBotics, we're ready
          to help you create revolutionary solutions. Contact us today and
          discover how we can make a difference together!
        </p>
        <a
          href="https://www.everybotics.it"
          target="_blank"
          rel="noopener noreferrer"
          className="text-palette3 font-semibold "
        >
          Visit EveryBotics
        </a>
      </div>
    </div>
  );
};

export default Section5;
