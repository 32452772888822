const milestones = [
  {
    title: "Certified Ethical Hacker",
    date: "2024",
    description: "CEH Cyber Security Certification",
  },
  {
    title: "MEng in Computer Engineering",
    date: "2015 – 2017",
    description: "MEng in Computer Engineering magna cum laude at UNICAS",
  },
  {
    title: "BEng in Computer and Telecommunication Engineering",
    date: "2012 – 2015",
    description: "BEng in Computer and Telecommunication Engineering at UNICAS",
  },
  {
    title: "Technical Institute Diploma",
    date: "2007 – 2012",
    description:
      "Technical Institute Diploma in Electronics and Telecommunication at ITIS Cassino",
  },
];

export default milestones;
